.Card {
  width: 75%;
  height: 400px;
  max-width: 1200px;

  background-color: rgb(255, 255, 255);
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1));
  backdrop-filter: blur(7.6px);
  -webkit-backdrop-filter: blur(7.6px);
  
  border-radius: 30px;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
}

.Card img{
  height: 360px;
  width: 360px;
  margin: auto;
  object-fit: scale-down;
}

.discription{
  width: 40%;
  margin: auto ;
  padding: 0 10px;
}

.discription-title {
  font-family: 'Inter', Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 45px;
  color: #6C63FF;

}
.discription-content {
  font-family: 'Inter', Helvetica, sans-serif;
  font-size: 23px;
  font-weight: 500;
  line-height: 30px;
  color: #4f4f4f;

  margin-top: 20px;
}

/* adapt to mobile devices |code below| */
@media screen and (max-width: 768px) {
  .Card {
    width: 90%;
    height: auto;
    flex-direction: column-reverse;
  }
  .Card img{
    width: 90%;
    margin: 10px auto;
  }
  .discription {
    width: 90%;
    margin: 30px auto;
  }
}