.carousel-container {
  height: 600px;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-around;

  margin-top: 50px;
  margin-bottom: 50px;

  transition: all 0.5s ease;
}
.carousel {
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.image {
  height: 100%;
  object-fit: contain;
  padding: 0 50px;
}

.detail {
  width: 500px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.detail-title {
  font-weight: 600;
  font-size: 40px;
  color: #6C63FF;
  margin: 10px;
}
.detail-description {
  font-weight: 500;
  font-size: 30px;
  color: #232323;
  margin: 0 50px;
}

@media screen and (max-width: 768px),
      screen and (max-aspect-ratio: 10/9){
        .carousel {
          flex-direction: column-reverse;
          width: 80%;
        }
        .image {
          width: 100%;
          object-fit: contain;
          padding: 0 20px;
        }
        .detail {
         align-self: center;
        }
        .detail-title {
          font-size: 30px;
          margin: 0px auto;
        }
        .detail-description {
          display: none;
        }
      }

.prev, .next{
  height: 100%;
  width: 20%; /* Carousel takes 80%, rest is for button */

  font-size: 50px;
  color: #6C63FF;
  font-weight: bold;
  
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prev:hover, .next:hover {
  -webkit-filter: drop-shadow(0px 0px 1px #222);
  filter: drop-shadow(0px 2px 5px rgb(191, 179, 255));
  cursor: pointer;
}