.title-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 90%;
    max-width: 800px;
    margin: auto;
    padding-top: 100px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(214, 214, 214);
  }
  .title-section h1 {
    display: inline-block;
    color: #6C63FF;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .title-section h6 {
    display: inline-block;
    color: #949494;
    font-size: 10px;
    font-weight: 400;
    line-height: 0;
  }
  
  .main-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin: 10px auto 200px auto;
    width: 90%;
    max-width: 800px;
  }
  .main-section p,
  .main-section li{
    font-weight: 400;
    font-size: 1rem;
    color: #1d1d1f;
    list-style-type: square;
    line-height: 1.5rem;
    margin: 0;
  }
  .main-section h4 {
    font-weight: 600;
    font-size: 1.3rem;
    color: #1d1d1f;
  
    margin: 2rem 0 0.9rem 0;
  }
  .main-section h5 {
    font-weight: 600;
    font-size: 1rem;
    color: #1d1d1f;

    margin: 0.3rem 0 0.5rem 0;
  }
  .main-section a{
    text-decoration: none;
    color: #6C63FF;
  }

  /* dark mode */
  @media (prefers-color-scheme: dark) {
    .privacyPolicy-container {
      background-color: black;
    }
    .main-section p,
    .main-section li,
    .main-section h4,
    .main-section h5 {
      color: white;
    }
  }