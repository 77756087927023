* {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
}
.splash {
  width: 100%;
  height: 100vh;
  background: linear-gradient(270deg, #C5C5FF 1.42%, rgba(242, 242, 255, 0.5) 98.31%);
  display: flex;
  justify-content: center;
}
.flyer-container {
  width: 80%;
  height: 80%;
  max-width: 1200px;
  display:flex;
  flex-direction: row;
  align-self: center;
}
.flyer-left {
  width: 40%;
  margin-top: 50px; /*50px is the height of top-bar*/
  /* background-color: aquamarine; */
  display: flex;
  flex-direction: column;
  justify-content:end;
  align-items:flex-end;
  padding-right: 50px; /* make a space between screenshot and right part */
  bottom: 0;
}
.flyer-left img {
  height: 100%;
  width: auto;
  object-fit: cover;
  bottom: 10px;
}
.flyer-right {
  margin-top: 50px;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-self: flex-end;
}
.flyer-right h1 {
  font-weight: 700;
  font-size: 80px;
  margin-bottom: 30px;
}
.flyer-container h3 {
  width: 93%;
  font-weight: 400;
  font-size: 30px;
  line-height: 30px;
}
.download-section {
  width: 93%;
  height: 300px;
  
  background: rgba(255, 255, 255, 0.49);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.6px);
  -webkit-backdrop-filter: blur(7.6px);
  border: 1px solid #6C63FF;
  border-radius: 30px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 10%;
  margin-bottom: 5px;
}
.GetTheApp {
  padding-bottom: 10px;
}
.QRCode-section {
  /* background-color: azure; */
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 70%;
  width: 100%;
}
.AppStore,
.GooglePlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.QRCode-section img {
  width: 90%;
  height: 100%;
}
.download-section-mobile {
  display: none;
}
.download-section-mobile img {
  height: 70px;
  margin: 5px;
}
/* adapt to mobile devices |code below| */
@media screen and (max-width: 768px),
      screen and (max-aspect-ratio: 10/9){
  .flyer-container {
    width: 90%;
    justify-content: center;
    justify-items: center;
    justify-self: center;
  }
  .flyer-left {
    display: none;
  }
  .flyer-right {
    width: 100%;
    height: 100%;
    margin: auto;
    align-items: center;
    align-self: center;
    justify-content:space-evenly;
  }
  .flyer-right h1 {
    color: #6C63FF;
  }
  .flyer-right h3 {
    text-align: center;
    font-size: 22px;
  }
  .download-section {
    display: none;
  }
  .download-section-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@supports (-webkit-touch-callout: none) { /* for pad devices */
  .download-section {
    height: 250px;
  }
  .download-section-mobile {
    display: flex;
    align-items: center;

  }
}
/* adapt to dektop*/
@media screen and (max-width: 768px) {

}
.connect {
  font-family: 'Inter';
  font-style: normal;
  text-align: center;
  color: #3D3D3D;
  padding-top: 60px;
  padding-bottom: 30px;
}

.slogan {
  width: 90%;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  margin: 30px auto;
}

.body {
  font-size: 22px;
  line-height: 30px;
  width: 80%;
  max-width: 1400px;
  margin: auto;
  margin-bottom: 100px;
}

/* adapt to mobile devices |code below| */
@media screen and (max-width: 768px){
  .body {
    font-size: 19px;
    line-height: 22px;
    width: 90%;
  }
}

.cards {
  padding: 1rem;
  margin-left: 150px;
  margin-right: 150px;
}

.title {
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 45px;
line-height: 58px;
color: #6C63FF;
margin-top: 50px;
margin-bottom: 50px;
margin-left: 40px;
}

.text {
  margin-bottom: 180px;
  margin-left: 40px;
}

.HowItWorks {
  text-align: center;
  font-size: 40px;
  font-weight: 500;

  margin-top: 200px;
}
.swiper {
  height: 500px;
  width: 100%;
}

.bottomBackground {
  background: #F2F2FF;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.bottomBody {
  margin: auto 30px auto 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: center;
}

.button {
  transition-duration: 0.4s;
  margin: 10% auto;
  border-radius: 22px;
  background-color: #F2F2FF;
  color: #6C63FF;
  border: 4px solid #6C63FF; 
  margin-top : 10%;
  /* font-size: 2.3vw;
  height: 6.5vw;
  width: 24vw; */

  font-size: calc(4px + 2.5vw);
  height: calc(20px + 6vw);
  width: calc(20px + 23vw);
}

.button:hover {
  background-color: #6C63FF; 
  color: #F2F2FF;
}

.bottomBackground img{
  max-width: 40%;
  height: 50vh;
  align-items: center;
  margin: auto 10% auto 0;
}

@media screen and (max-width: 768px){
  .bottomBody {
    margin: auto 10% auto 10%;
    text-align: center;
  }
  .button {
    font-size: calc(4px + 5vw);
    height: calc(20px + 12vw);
    width: calc(20px + 41vw);
    margin: 10% auto;
    transition-duration: 0.4s;
    border-radius: 22px;
    background-color: #F2F2FF;
    color: #6C63FF;
    border: 4px solid #6C63FF; 
    margin-top : 15%;
  }
  .bottomBackground img {
    display: none;
  }
}

/* @media (prefers-color-scheme: dark) {
  .connect {
    color: white;
    background-color: #111111;
  }
} */