.topbar-container {
  display: flex;
  height: 50px;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;

  /* Glassmorphism */
  background: rgba(255, 255, 255, 0.49);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.6px);
  -webkit-backdrop-filter: blur(7.6px);
  border: 1px solid rgba(255, 255, 255, 0.17);
}

/* dark mode */
@media (prefers-color-scheme: dark) {
  .topbar-container {
    background: rgba(76, 76, 76, 0.49);
    border: 0;
  }
}

.demic-logo {
  height: 50px;
  margin-left: 20px;
}
.demic-logo:hover {
  -webkit-filter: drop-shadow(0px 0px 1px #222);
  filter: drop-shadow(0px 2px 5px rgb(191, 179, 255));
  cursor: pointer;
}
