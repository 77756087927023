body, html{  
  margin:0;     /* remove all the margin */
  padding:0;
}
.bottombar-container {  /* The whole purple box */
  width: 100%;
  height: 150px;
  background-color: #6C63FF;

  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.links { /*text location*/
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-left: 15%;
  margin-right: 15%;
}

.bottombar-container p { /*for text that don't have link*/
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  color: white;
  font-size: 0.6rem;
  display: inline-block;
  margin: 0.3rem;
}
.link { /*for text that DO have link*/
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  color: white;
  font-size: 0.6rem;
  display: inline-block;
  margin: 0.3rem;
  text-decoration: none;
  text-align:center;
}
.link:hover {
  cursor: pointer;
  text-decoration: underline;
  color: white;
}
.link img:hover {
  transform: scale(1.5);
}
